/**
 * @author https://gitee.com/chu1204505056/vue-admin-better （不想保留author可删除）
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import {
  asyncRoutes,
  constantRoutes,
  pagaCloudBackgroundRoutes,
  shopMallBackgroundRoutes,
} from '@/router'
import {
  getRouterList,
  v2getPermissionsList,
  v2getUserMenuList,
  getPcMenuLis,
  v2getMyapplication,
  v2switchMyapplication,
} from '@/api/router'
import netConfig from '@/config/net.config'
import {
  convertRouter,
  filterAsyncRoutes,
  filterRoutesIcon,
} from '@/utils/handleRoutes'
import Layout from '@/layouts'
import { clearRoutesPermissions } from '../../utils/handleRoutes'

const state = () => ({
  routes: [],
  partialRoutes: [],
  v2AllAuth: '', // 全部权限
  v2Auth: '', // 用户个人权限
  backstageList: [], // 应用后台列表
  backstageDetail: '', // 当前应用后台详情
  /**
   * @description 特殊处理，二级分组没有下级
   * @todo noLowerList数组里存放二级的meta的key
   * @params auth传入看看是否需要特殊处理
   */
  // noLowerList: ['goods_list', 'message_users', 'card_users', 'announcement'],
})
const getters = {
  routes: (state) => state.routes,
  partialRoutes: (state) => state.partialRoutes,
}
const mutations = {
  setRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes)
  },
  setAllRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes)
  },
  setPartialRoutes(state, routes) {
    state.partialRoutes = constantRoutes.concat(routes)
  },
  setv2AllAuth(state, v2AllAuth) {
    state.v2AllAuth = v2AllAuth
  },
  setBackstageList(state, backstageList) {
    state.backstageList = backstageList
  },
  setBackstageDetail(state, backstageDetail) {
    state.backstageDetail = backstageDetail
  },
}

const actions = {
  async setRoutes({ commit, rootState }, permissions) {
    //开源版只过滤动态路由permissions，admin不再默认拥有全部权限
    let temporaryRoute = []
    /**
     * @todo 登录用户权限
     * @returns ["dealer", "user", "content", "clue", "order", "product"]
     */
    if (
      process.env.VUE_APP_TAG === 'paiplus' &&
      process.env.VUE_APP_API_URL === 'https://api.t.paiplus.cn'
    ) {
      temporaryRoute = filterRoutesIcon(asyncRoutes)
      temporaryRoute = clearRoutesPermissions(asyncRoutes)
      // console.log(temporaryRoute, '派加云测试环境的路由主体')
    }
    // console.log(
    //   process.env.VUE_APP_TAG,
    //   process.env.NODE_ENV,
    //   process.env.VUE_APP_API_URL,
    //   '.env配置文件预览'
    // )
    // console.log(filterRoutesIcon(asyncRoutes), '渲染出来的router routes.js')

    if (
      process.env.VUE_APP_TAG === 'paiplus' &&
      process.env.VUE_APP_API_URL === 'https://api2.paiplus.cn'
    ) {
      temporaryRoute = filterRoutesIcon(asyncRoutes)
    }

    // TODO 卡百利使用所有菜单
    if (process.env.VUE_APP_TAG === 'kabel') {
      temporaryRoute = filterRoutesIcon(asyncRoutes)
    }

    const userInfo = JSON.parse(localStorage.getItem('user-info'))
    // 美之选只保留SCRM功能和组织架构通讯录 - 定制化
    if (
      userInfo.user &&
      (userInfo.user.company_id === 10001 ||
        userInfo.user.company.brand_company_id === 10001)
    ) {
      temporaryRoute = [
        {
          path: '/',
          component: Layout,
          redirect: { name: 'Clue' },
        },
        // TODO scrm新版本线索菜单（派加使用新版本菜单，Kabel使用旧版本）
        {
          path: '/scrm',
          name: 'scrm',
          component: Layout,
          redirect: 'noRedirect',
          alwaysShow: true, // 是否显示下一级
          meta: { title: 'SCRM', icon: 'indent', auth: 'scrm' },
          children: [
            {
              path: 'clue',
              name: 'Clue',
              redirect: { name: 'clueList' },
              component: () => import('@/views/clue/index'),
              alwaysShow: true, // 是否显示下一级
              meta: { title: '线索', icon: 'indent', auth: 'clues' },
              children: [
                {
                  path: 'list',
                  name: 'clueList',
                  component: () => import('@/views/clue/toc/list/index'),
                  meta: {
                    title: '线索列表',
                    icon: 'list-alt',
                    auth: 'clues_list',
                    // icon: 'file-import',
                    // permissions: ['common'],
                  },
                },
                {
                  path: 'import',
                  name: 'ClueImport',
                  component: () => import('@/views/clue/toc/import/index'),
                  meta: {
                    title: '线索导入',
                    icon: 'file-import',
                    auth: 'clues_import',
                    permissions: ['common'],
                  },
                },
              ],
            },
            {
              path: 'setting',
              name: 'Setting',
              component: () => import('@/views/clue/index'),
              alwaysShow: true,
              meta: {
                title: '设置',
                icon: 'indent',
                auth: 'scrm_setting',
              },
              children: [
                {
                  path: 'clueSetting',
                  name: 'ClueSetting',
                  component: () => import('@/views/clue/setting/ClueSetting'),
                  meta: {
                    title: '线索设置',
                    icon: 'edit',
                    auth: 'clues_setting',
                    permissions: ['common'],
                  },
                },
              ],
            },
          ],
        },
      ]
      // 不等于0代表非超管，其他均为企业所有人，或者超管等等
      if (userInfo.role !== 0) {
        temporaryRoute.push(
          // TODO 用户中心
          {
            path: '/user',
            component: Layout,
            redirect: 'noRedirect',
            name: 'User',
            alwaysShow: false,
            meta: {
              title: '用户中心',
              icon: 'indent',
              auth: 'user',
              permissions: ['brand_manager', 'shop_manager'],
            },
            children: [
              {
                path: 'roleList',
                name: 'roleList',
                component: () => import('@/views/user/role/list'),
                meta: {
                  title: '角色列表',
                  icon: 'border-all',
                  permissions: ['brand_manager', 'shop_manager'],
                },
              },
              {
                path: 'departmentList',
                name: 'departmentList',
                component: () => import('@/views/department/index/list'),
                meta: {
                  title: '组织架构',
                  icon: 'border-all',
                  permissions: ['brand_manager', 'shop_manager'],
                },
              },
              {
                path: 'list/detail',
                name: 'detail',
                component: () => import('@/views/user/index/detail'),
              },
              {
                path: 'roleList/detail',
                name: 'detail',
                component: () => import('@/views/user/role/detail'),
              },
              {
                path: 'list/quitList',
                name: 'quitList',
                component: () => import('@/views/user/index/quitList'),
              },
            ],
          }
        )
      }
    }

    const finallyAsyncRoutes = await filterAsyncRoutes(
      temporaryRoute,
      permissions
    )
    console.log(finallyAsyncRoutes)
    commit('setRoutes', finallyAsyncRoutes)
    return finallyAsyncRoutes
  },
  async setAllRoutes({ commit }) {
    let { data } = await getRouterList()
    data.push({
      path: '*',
      redirect: '/404',
      meta: { icon: 'indent', title: '404页面' },
      hidden: true,
    })
    let accessRoutes = convertRouter(data)
    commit('setAllRoutes', accessRoutes)
    return accessRoutes
  },
  setPartialRoutes({ commit }, accessRoutes) {
    commit('setPartialRoutes', accessRoutes)
    return accessRoutes
  },

  // 我的应用列表
  async v2getMyapplication({ commit }) {
    const data = await v2getMyapplication()
    const backstage = localStorage.getItem('backstage')
    console.info(data, '我的应用列表')
    // 当前应用后台
    data.filter((item) => {
      if (Number(item.id) === Number(backstage)) {
        commit('setBackstageDetail', item)
        localStorage.setItem('backstageDetail', JSON.stringify(item))
      }
    })
    commit('setBackstageList', data)
    return data
  },

  // 应用切换
  async v2switchMyapplication(context, data) {
    console.info(data, 'ppppppppp')
    const res = await v2switchMyapplication(data)
    console.info(res, '应用切换')
    localStorage.setItem('user-info', JSON.stringify(res))
    localStorage.setItem('kabel-token', res.token)
    localStorage.setItem('old-kabel-token', res.token)
    return res
  },

  // 功能权限列表
  async v2getPermissionsList({ commit }) {
    const data = await v2getPermissionsList()
    // 本地和vuex共存
    commit('setv2AllAuth', data)
    // state.v2AllAuth = data
    localStorage.setItem('v2AllAuth', JSON.stringify(data))
    console.info(data, '功能权限列表')
    return data
  },

  // 获取登录用户菜单功能权限列表
  async v2getUserMenuList() {
    const data = await v2getUserMenuList()
    // 本地和vuex共存
    // state.v2Auth = data
    /**
     * 动过权限部分 二级菜单已经不用添加权限key
     */
    // data.menu.goods_list = []
    // data.menu.goods_setting = []
    // data.menu.message_users = []
    // data.menu.card_users = []
    // data.menu.announcement = []
    // data.menu.brand_list = []
    // data.menu.offline_order = []
    // data.menu.offline_order_set = []
    localStorage.setItem('v2Auth', JSON.stringify(data))
    console.info(data, '获取登录用户菜单功能权限列表')
    return data
  },

  // 获取登录用户菜单功能权限列表
  async getPcMenuLis() {
    let temporaryRoute = []
    const data = await getPcMenuLis()
    // 本地和vuex共存
    // state.v2Auth = data
    localStorage.setItem('pcMenuLis', JSON.stringify(data))
    console.info(data, '获取登录用户菜单功能权限列表')
    return data
  },
}
export default { state, getters, mutations, actions }
