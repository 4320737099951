/**
 * @author https://gitee.com/chu1204505056/vue-admin-better （不想保留author可删除）
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */

import Vue from 'vue'
import { login, logout } from '@/api/user'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '@/utils/accessToken'
import { removeUserInfo } from '@/utils/userInfo'
import { resetRouter } from '@/router'
import {
  title,
  tokenName,
  apiResponseTokenName,
  apiHostProxy,
  apitHostTest,
  apitHostRelease,
  baseURL,
} from '@/config'
import { systemConst, kabelConst, paiplusConst } from '@/config/const'
import { getUserInfo, setUserInfo } from '@/utils/userInfo'

/**
 * 获取菜单权限
 * @param {*} userData
 * @returns
 */
function getPermissions(userData) {
  const userInfo = userData['user'] // 用户登录信息的user
  /**
   * 设置权限类型
   *  common：普通用户
   *  developer：开发者
   *  brand_manager：品牌商超管
   *  brand_user：品牌商用户
   *  shop_manager：门店超管
   *  shop_user：门店用户
   *  shop_goods_user: 允许访问商品信息的用户
   *  shop_card_manage: 允许访问购物卡管理权限的部门
   */
  let permissions = ['common']

  // 测试返回全部菜单
  if (baseURL == apiHostProxy || baseURL == apitHostTest) {
    return [
      'common', // 公共
      'developer',
      'brand_manager', //是否是品牌管理员
      'brand_user', // 品牌除超管外其他人
      'shop_manager', //是否是门店管理员
      'shop_user', // 门店除超管外其他人
      'shop_goods_user',
      'finance_manager', // 财务管理
      'business_manager',
      'dealer_manager',
      'telesales',
      'warehouse',
      'shop_card_manage', // 拥有购物卡的权限字段
    ]
  }
  // 开发者
  if (
    (baseURL == apitHostTest &&
      systemConst.testDeveloperIds.includes(userInfo.id)) ||
    (baseURL == apitHostRelease &&
      systemConst.releaseDeveloperIds.includes(userInfo.id))
  ) {
    permissions.push('developer')
  }

  // 判断当前用户所在的部门 是否在写死配置项的部门中，在的话返回true 表示有这个特殊权限，不在的话，返回false 则就没有这个特殊权限，则不展示路由
  const checkDepartmentAccess = (departmentIds) =>
    departmentIds.some((allowDeptId) =>
      userInfo.department_ids.includes(allowDeptId)
    )

  const isManger = systemConst.roleManger.includes(userData.role) // 是否是管理员
  const isFinance = checkDepartmentAccess(systemConst.financeDepartmentIds) // 是否是财务部
  const isBusiness = checkDepartmentAccess(systemConst.businessDepartmentIds) // 是否是商务部
  const isTelesales = checkDepartmentAccess(systemConst.telesalesIds)
  const isWarehouse = checkDepartmentAccess(systemConst.warehouseIds)
  const isShopCard =
    checkDepartmentAccess(systemConst.shopCardDepartmentIds) ||
    checkDepartmentAccess(systemConst.onlyViewShopCardDepartmentIds) // 处理购物卡管理路由的权限
  const isPowerShop = systemConst.powerShopId.includes(userInfo.company_id)
  let userType = ''

  // 企业类型 1 品牌 2 门店
  switch (userInfo.company.type) {
    case systemConst.typeBrand: // 品牌
      userType = isManger ? 'brand_manager' : 'brand_user' // 是否是管理员
      permissions.push(userType)
      permissions.push('is_brand')
      if (isFinance) permissions.push('finance_manager')
      if (isBusiness) permissions.push('business_manager')
      if (isShopCard) permissions.push('shop_card_manage')
      break
    case systemConst.typeShop: // 门店
      userType = isManger ? 'shop_manager' : 'shop_user' // 是否是管理员
      permissions.push(userType)
      permissions.push('is_shop')
      break
  }

  // 门店演示账号才允许查看门店端的商品中心
  if (isPowerShop) {
    permissions.push('powerShop')
  }

  if (
    paiplusConst.orderMenuCompanyIds.includes(userInfo.company.id) ||
    paiplusConst.orderMenuCompanyIds.includes(userInfo.company.brand_company_id)
  ) {
    //小福蝶等，直接显示订单中心
    permissions.push('shop_goods_user')
  }

  // 对卡百利品牌做单独的特殊权限控制
  if (userInfo.company.id == kabelConst.companyId) {
    const intersectLen = checkDepartmentAccess(kabelConst.orderMenuDeptIds)
    const dealerLen = checkDepartmentAccess(kabelConst.dealerMenuDeptIds)
    const dealerView = checkDepartmentAccess(systemConst.viewDealers)
    const isOrderPeople = kabelConst.orderMenuUserIds.filter(
      (allowDeptId) => Number(userInfo.id) === Number(allowDeptId)
    ).length

    if (intersectLen) permissions.push('shop_goods_user') // 拥有商品菜单的列表
    if (isOrderPeople) permissions.push('shop_goods_people', 'shop_goods_user') //特殊的人，拥有全部订单权限
    if (isTelesales) permissions.push('telesales') //电销部有的菜单
    if (isWarehouse) permissions.push('warehouse') //仓库管理员有的菜单
    if (dealerLen) permissions.push('dealer_manager') // 拥有经销商菜单的列表
    if (dealerView) permissions.push('viewDealers') // 拥有经销商菜单列表，但是仅有查看权限，无编辑权限
  }

  console.log(permissions, '权限字段')
  return permissions
}

const state = () => ({
  accessToken: getAccessToken(),
  username: '',
  avatar: '',
  permissions: [],
})
const getters = {
  accessToken: (state) => state.accessToken,
  userInfo: (state) => state.userInfo,
  username: (state) => state.userInfo['user']['name'],
  companyInfo: (state) => state.userInfo['user']['company'],
  avatar: (state) => state.avatar,
  permissions: (state) => state.permissions,
}
const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  // setUsername(state, username) {
  //   state.username = username
  // },
  // setCompanyInfo(state, companyInfo) {
  //   state.companyInfo = companyInfo
  // },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    setUserInfo(userInfo)
  },
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
}
const actions = {
  setPermissions({ commit }, permissions) {
    commit('setPermissions', permissions)
  },
  async login({ commit }, userInfo) {
    const data = await login(userInfo)
    const accessToken = data[apiResponseTokenName]
    if (accessToken) {
      commit('setAccessToken', accessToken)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      Vue.prototype.$baseNotify(`欢迎登录${title}`, `${thisTime}！`)
      // 设置用户信息
      setUserInfo(data)
    } else {
      Vue.prototype.$baseMessage(
        `登录接口异常，未正确返回${apiResponseTokenName}...`,
        'error'
      )
    }
  },
  async setUserInfo({ commit }, data) {
    const accessToken = data[apiResponseTokenName]
    if (accessToken) {
      commit('setAccessToken', accessToken)
      // 设置用户信息
      setUserInfo(data)

      // 由于 setUserInfo 的时候对数据进行了格式化，因此重新获取格式化后的用户信息
      const userInfo = getUserInfo()
      const permissions = getPermissions(userInfo)
      commit('setPermissions', permissions)
      return permissions
    } else {
      Vue.prototype.$baseMessage(
        `登录接口异常，未正确返回${apiResponseTokenName}...`,
        'error'
      )
    }
  },
  async getUserInfo({ commit, state }) {
    const userData = getUserInfo()
    // const userInfo = userData['user']

    const permissions = getPermissions(userData)
    commit('setPermissions', permissions)
    // commit('setUsername', userInfo['name'])
    commit('setUserInfo', userData)
    // commit('setCompanyInfo', userInfo['company'])
    commit('setAvatar', [])
    return permissions

    // const { data } = await getUserInfo(state.accessToken)
    // if (!data) {
    //   Vue.prototype.$baseMessage('验证失败，请重新登录...', 'error')
    //   return false
    // }
    // let { permissions, username, avatar } = data
    // if (permissions && username && Array.isArray(permissions)) {
    //   commit('setPermissions', permissions)
    //   commit('setUsername', username)
    //   commit('setAvatar', avatar)
    //   return permissions
    // } else {
    //   Vue.prototype.$baseMessage('用户信息接口异常', 'error')
    //   return false
    // }
  },
  async logout({ dispatch }) {
    await logout(state.accessToken)
    await dispatch('resetAccessToken')
    await resetRouter()
  },
  resetAccessToken({ commit }) {
    commit('setPermissions', [])
    commit('setAccessToken', '')
    removeAccessToken()
    removeUserInfo()
    localStorage.removeItem('region') //清除缓存省市区数据
  },
}
export default { state, getters, mutations, actions }
