/**
 * @description 系统常量
 **/

//  permission: 'user/permissions', 用户所有的写死的权限字符串列表已经存放在vuex中，使用computed 直接获取即可

// 企业相关常量 派加云和卡百利云公用的常量，部门可以设置任何品牌的部门id
export const systemConst = {
  // 企业类型
  typeBrand: 1, // 品牌商
  typeShop: 2, // 门店
  // 开发者id
  testDeveloperIds: [
    100071, 100072, 100073, 100074, 101058, 101207, 100734, 102411,
  ],
  releaseDeveloperIds: [18371, 18480, 18965, 20184, 22802, 22801],

  // 角色类型
  roleManger: [1, 2, 3], // 企业管理员角色（负责人、超管、管理员）
  //财务部
  financeDepartmentIds: [2859],
  //商务部
  businessDepartmentIds: [2809, 2837], //所有品牌的商务财务，都可以配置在这里，公用路由权限
  //电销部
  telesalesIds: [2846, 2860, 2946, 2947],
  //仓库管理员
  warehouseIds: [2853, 3051],
  // 拥有经销商菜单列表，但是仅有查看权限，无编辑权限
  viewDealers: [2860, 2946, 2947],
  // 门店演示账号才允许查看门店端的商品中心
  powerShopId: [11154],
  shopCardDepartmentIds: [2859, 14], //除了管理员以外，所有品牌拥有购物卡管理路由菜单入口 和 所有操作权限的部门id
  onlyViewShopCardDepartmentIds: [2809, 2837, 2447], //有购物卡入口，但是只有查看权限的部门ids --- 使用了自定义指令控制权限
}

// 卡百利相关常量
export const kabelConst = {
  // 企业id
  companyId: 10000,
  // 允许访问订单菜单列表的部门id
  orderMenuDeptIds: [3830, 2859, 14, 2184],
  // 允许访问订单菜单列表的用户id
  orderMenuUserIds: [23197, 102187, 25118],
  // 企业超管
  // superManger: 17745,
  superManger: [17745, 100406, 18437, 17744, 17728, 24095],
  // 允许导出 toc 线索的用户id
  exportTocUserIds: [18437, 24095],
  // 允许访问经销商列表的部门id
  dealerMenuDeptIds: [2859, 2809, 3830, 3178],
  // 允许导出固定线索来源列表的人员id
  allowExportFixedSourse: [20510, 24579, 23768, 20505, 100406, 101162],
}
// 派加相关常量
export const paiplusConst = {
  // 允许访问订单菜单列表的用户id
  orderMenuCompanyIds: [12806],
}
