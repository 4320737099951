import Vue from 'vue'
import axios from 'axios'
import {
  baseURL,
  contentType,
  debounce,
  invalidCode,
  noPermissionCode,
  requestTimeout,
  successCode,
  tokenName,
  loginInterception,
} from '@/config'
import { getMineTypeByFileName } from '@/utils'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'

let loadingInstance

/**
 * 检查响应结果是否json数据
 * @param {*} cType
 * @returns
 */
const checkResponseIsJsonData = (cType) => {
  return cType.toLowerCase().indexOf('application/json') !== -1
}

/**
 *
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleErrorCode = (code, msg) => {
  code = code || 400
  msg = msg || '服务器异常，请稍后再试。'

  // console.log(code)
  switch (code) {
    case invalidCode:
      Vue.prototype.$baseMessage(msg, 'error')
      store.dispatch('user/resetAccessToken').catch(() => {})
      if (loginInterception) {
        location.reload()
      }
      break
    // case noPermissionCode:
    //   router.push({ path: '/401' }).catch(() => {})
    //   break
    default:
      Vue.prototype.$baseMessage(msg, 'error')
      break
  }
}

/**
 * @description 处理响应结果
 * @param {*} data
 * @returns
 */
const handleResponse = (config, data) => {
  // console.info(config, data, 'data:')
  const { code, msg } = data
  // console.log(code, msg)
  // 操作正常Code数组
  const codeVerificationArray = isArray(successCode)
    ? [...successCode]
    : [...[successCode]]
  // 是否操作正常
  if (codeVerificationArray.includes(code)) {
    return data.data
  } else {
    handleErrorCode(code, msg)
    return Promise.reject(
      '请求异常拦截:' + JSON.stringify({ url: config.url, code, msg }) ||
        'Error'
    )
  }
}

const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken']) {
      config.headers[tokenName] = store.getters['user/accessToken']
    }
    // 应用类型，1：小程序；2：业务中台
    config.headers['App-Type'] = 2
    // 测试使用
    // config.headers['Cookie'] = 'XDEBUG_SESSION=PHPSTORM'
    //这里会过滤所有为空、0、false的key，如果不需要请自行注释
    // if (config.data)
    //   config.data = Vue.prototype.$baseLodash.pickBy(
    //     config.data,
    //     Vue.prototype.$baseLodash.identity
    //   )

    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = qs.stringify(config.data)
      const contentType = config.headers['Content-Type']
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8'
    }
    if (debounce.some((item) => config.url.includes(item)))
      loadingInstance = Vue.prototype.$baseLoading()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) loadingInstance.close()

    const { data, config, headers } = response

    const contentType = headers['content-type'] || ''

    const isJson = checkResponseIsJsonData(contentType)

    // 不是 json 格式（防止接口报错），并且设置了文件名，视为文件下载
    if (isJson === false && config.fileName) {
      const blob = new Blob([data], {
        type: contentType || getMineTypeByFileName(config.fileName),
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', config.fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
      return true
    }

    if (isJson && data instanceof Blob) {
      let reader = new FileReader()
      reader.readAsBinaryString(data)
      // reader.readAsText(data, 'utf8')
      reader.onload = (e) => {
        let res = e.target.result
        return handleResponse(config, JSON.parse(res))
      }
    } else {
      return handleResponse(config, data)
    }

    // const { code, msg } = data
    // // 操作正常Code数组
    // const codeVerificationArray = isArray(successCode)
    //   ? [...successCode]
    //   : [...[successCode]]
    // // 是否操作正常
    // if (codeVerificationArray.includes(code)) {
    //   return data.data
    // } else {
    //   handleErrorCode(code, msg)
    //   return Promise.reject(
    //     '请求异常拦截:' + JSON.stringify({ url: config.url, code, msg }) ||
    //       'Error'
    //   )
    // }
  },
  (error) => {
    if (loadingInstance) loadingInstance.close()
    const { response, message } = error
    if (error.response && error.response.data) {
      const { status, data } = response
      handleErrorCode(status, data.msg || message)
      return Promise.reject(error)
    } else {
      // console.log(error, '测试error:')
      let { message } = error
      if (message === 'Network Error') {
        message = '后端接口连接异常'
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时'
      }
      if (message.includes('请求失败')) {
        const code = message.substr(message.length - 3)
        message = '后端接口' + code + '异常'
      }
      Vue.prototype.$baseMessage(message || `后端接口未知异常`, 'error')
      return Promise.reject(error)
    }
  }
)
/**
 *
 * @param {import('axios').AxiosRequestConfig<any>} params
 * @returns {Promise<any,any>}
 */
export const getRequest = (params) => {
  console.log('params: ', params)
  return instance.request({ method: 'GET', ...params })
}

/**
 *
 * @param {import('axios').AxiosRequestConfig<any>} params
 * @returns {Promise<any,any>}
 */
export const postRequest = (params) => {
  return instance.request({ method: 'POST', ...params })
}

export default instance
