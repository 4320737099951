import { systemConst } from '@/config/const'

// 判断当前用户所在的部门是否在配置项的部门中
const checkDepartmentAccess = (departmentIds, userInfo) =>
  departmentIds.some((allowDeptId) =>
    userInfo.user?.department_ids.includes(allowDeptId)
  )

const onlyViewShopCard = {
  // 踩坑1： 使用自定义指令，不要使用bind 有时候组件是异步渲染的，会导致拿不到父节点，自定义指令失效，此时可以使用inserted 这样避免dom异步渲染的问题
  inserted(el, binding, vnode) {
    // 确保父节点存在
    if (el.parentNode) {
      // 用户登录信息
      const userInfo = JSON.parse(localStorage.getItem('user-info'))
      let hasAccess = checkDepartmentAccess(
        systemConst.onlyViewShopCardDepartmentIds,
        userInfo
      )
      // 是否是管理员
      let isRole = systemConst.roleManger.includes(userInfo.role)
      // 判断不是管理员并且在只读部门中，则移除元素
      if (!isRole && hasAccess) {
        el.parentNode.removeChild(el)
      }
    } else {
      console.warn('Parent node is null, cannot remove the element.')
    }
  },
}

export default {
  onlyViewCard: onlyViewShopCard,
}
