/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，vip文档中已提供路由的基础图标与小清新图标的配置方案，请仔细阅读
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts'
import { publicPath, routerMode } from '@/config'
import { pagaCloudBackground } from './modules/pagaCloudBackground'
import { shopMallBackground } from './modules/shopMallBackground'
import netConfig from '@/config/net.config'

Vue.use(VueRouter)
const includPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch((err) => err)
}

export const IS_BRAND_SHOPCENTER = 'is_brand_shopcenter'
export const IS_SHOP_SHOPCENTER = 'is_shop_shopcenter'
export const brand_shop_center = {
  path: '/packardCloudProd',
  component: Layout,
  redirect: 'noRedirect',
  name: 'packardCloudProd',
  alwaysShow: true,
  meta: {
    title: '商品中心（品牌）',
    icon: 'indent',
    permissions: ['is_brand'],
    routerChild: IS_BRAND_SHOPCENTER,
    // permissions: ['shop_goods_user', 'warehouse'],
    cache: false,
  },
  children: [
    {
      path: 'basicData',
      name: 'BasicData',
      component: () => import('@/views/packardCloudProd/pages/basicData/index'),
      alwaysShow: false, // 是否显示下一级
      redirect: { name: 'ClassSettings' },
      meta: {
        title: '基本数据',
        icon: 'border-all',
        cache: false,
        routerChild: IS_BRAND_SHOPCENTER,
      },
      children: [
        {
          path: 'classSettings',
          name: 'ClassSettings',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/basicData/classSettings/index'
            ),
          hidden: true,
          meta: {
            title: '分类设置',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/basicData',
          },
        },
        {
          path: 'unit',
          name: 'Unit',
          component: () =>
            import('@/views/packardCloudProd/pages/basicData/unit/index'),
          meta: {
            title: '单位',
            icon: 'file-import',
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/basicData',
          },
          hidden: true,
        },
        {
          path: 'attributeParameterSetting',
          name: 'AttributeParameterSetting',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/basicData/attributeParameterSetting/index'
            ),
          redirect: { name: 'AttributesList' },
          meta: {
            title: '属性参数设置',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/basicData',
          },
          hidden: true,
          children: [
            {
              path: 'attributesList',
              name: 'AttributesList',
              component: () =>
                import(
                  '@/views/packardCloudProd/pages/basicData/attributeParameterSetting/attributesList'
                ),
              meta: {
                title: '自定属性列表',
                icon: 'border-all',
                permissions: ['common'],
                routerChild: IS_BRAND_SHOPCENTER,
                cache: false,
                activeMenu: '/packardCloudProd/basicData',
              },
              hidden: true,
            },
            {
              path: 'editCustomAttributes',
              name: 'EditCustomAttributes',
              component: () =>
                import(
                  '@/views/packardCloudProd/pages/basicData/attributeParameterSetting/editCustomAttributes'
                ),
              meta: {
                title: '属性编辑',
                icon: 'border-all',
                permissions: ['common'],
                routerChild: IS_BRAND_SHOPCENTER,
                cache: false,
                activeMenu: '/packardCloudProd/basicData',
              },
              hidden: true,
            },
          ],
        },
      ],
    },
    {
      path: 'commodityManagement',
      name: 'CommodityManagement',
      component: () =>
        import('@/views/packardCloudProd/pages/commodityManagement/index'),
      alwaysShow: false, // 是否显示下一级
      redirect: { name: 'ProdList' },
      meta: {
        title: '商品管理',
        icon: 'border-all',
        routerChild: IS_BRAND_SHOPCENTER,
        cache: false,
      },
      children: [
        {
          path: 'prodList',
          name: 'ProdList',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/commodityManagement/prodList'
            ),
          hidden: true,
          meta: {
            title: '商品列表',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/commodityManagement',
          },
        },
        {
          path: 'essentialInfo',
          name: 'EssentialInfo',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/commodityManagement/essentialInfo'
            ),
          hidden: true,
          meta: {
            title: '商品基本信息',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/commodityManagement',
          },
        },
        {
          path: 'singleProduct',
          name: 'SingleProduct',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/commodityManagement/singleProduct'
            ),
          hidden: true,
          meta: {
            title: '单品产品规格属性',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/commodityManagement',
          },
        },
        {
          path: 'portfolioProducts',
          name: 'PortfolioProducts',
          icon: 'file-import',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/commodityManagement/portfolioProducts'
            ),
          hidden: true,
          meta: {
            title: '组合产品规格属性',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/commodityManagement',
          },
        },
        {
          path: 'graphicDetails',
          name: 'GraphicDetails',
          component: () =>
            import(
              '@/views/packardCloudProd/pages/commodityManagement/graphicDetails'
            ),
          hidden: true,
          meta: {
            title: '商品基图文详情',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_BRAND_SHOPCENTER,
            activeMenu: '/packardCloudProd/commodityManagement',
          },
        },
      ],
    },
  ],
}
export const shop_shop_center = {
  path: '/shopMallProd',
  // path: '/packardCloudProd',
  component: Layout,
  redirect: 'noRedirect',
  name: 'shopMallProd',
  alwaysShow: true,
  meta: {
    title: '商品中心（门店）',
    icon: 'indent',
    permissions: ['powerShop'],
    routerChild: IS_SHOP_SHOPCENTER,
    // permissions: ['shop_goods_user', 'warehouse'],
    cache: false,
  },
  children: [
    {
      path: 'basicData',
      name: 'BasicData',
      component: () => import('@/views/shopMallProd/pages/basicData/index'),
      alwaysShow: false, // 是否显示下一级
      redirect: { name: 'shopClassSettings' },
      meta: {
        title: '基本数据',
        icon: 'border-all',
        routerChild: IS_SHOP_SHOPCENTER,
        cache: false,
      },
      children: [
        {
          path: 'classSettings',
          name: 'shopClassSettings',
          component: () =>
            import('@/views/shopMallProd/pages/basicData/classSettings/index'),
          hidden: true,
          meta: {
            title: '分类设置',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/basicData',
          },
        },
        {
          path: 'unit',
          name: 'Unit',
          component: () =>
            import('@/views/shopMallProd/pages/basicData/unit/index'),
          meta: {
            title: '单位',
            icon: 'file-import',
            activeMenu: '/shopMallProd/basicData',
          },
          hidden: true,
        },
        {
          path: 'label',
          name: 'Label',
          component: () =>
            import('@/views/shopMallProd/pages/basicData/label/index'),
          meta: {
            title: '标签',
            icon: 'file-import',
            activeMenu: '/shopMallProd/basicData',
            routerChild: IS_SHOP_SHOPCENTER,
            // auth: 'goods_unit',
          },
          hidden: true,
        },
        {
          path: 'attributeParameterSetting',
          name: 'AttributeParameterSetting',
          component: () =>
            import(
              '@/views/shopMallProd/pages/basicData/attributeParameterSetting/index'
            ),
          redirect: { name: 'shopAttributesList' },
          meta: {
            title: '属性参数设置',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/basicData',
          },
          hidden: true,
          children: [
            {
              path: 'attributesList',
              name: 'shopAttributesList',
              component: () =>
                import(
                  '@/views/shopMallProd/pages/basicData/attributeParameterSetting/attributesList'
                ),
              meta: {
                title: '自定属性列表',
                icon: 'border-all',
                permissions: ['common'],
                routerChild: IS_SHOP_SHOPCENTER,
                cache: false,
                activeMenu: '/shopMallProd/basicData',
              },
              hidden: true,
            },
            {
              path: 'editCustomAttributes',
              name: 'EditCustomAttributes',
              component: () =>
                import(
                  '@/views/shopMallProd/pages/basicData/attributeParameterSetting/editCustomAttributes'
                ),
              meta: {
                title: '属性编辑',
                icon: 'border-all',
                permissions: ['common'],
                routerChild: IS_SHOP_SHOPCENTER,
                cache: false,
                activeMenu: '/shopMallProd/basicData',
              },
              hidden: true,
            },
          ],
        },
      ],
    },
    {
      path: 'commodityManagement',
      name: 'CommodityManagement',
      component: () =>
        import('@/views/shopMallProd/pages/commodityManagement/index'),
      alwaysShow: false, // 是否显示下一级
      redirect: { name: 'shopProdList' },
      meta: {
        title: '商品管理',
        icon: 'border-all',
        cache: false,
      },
      children: [
        {
          path: 'prodList',
          name: 'shopProdList',
          component: () =>
            import('@/views/shopMallProd/pages/commodityManagement/prodList'),
          hidden: true,
          meta: {
            title: '商品列表',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/commodityManagement',
          },
        },
        {
          path: 'essentialInfo',
          name: 'EssentialInfo',
          component: () =>
            import(
              '@/views/shopMallProd/pages/commodityManagement/essentialInfo'
            ),
          hidden: true,
          meta: {
            title: '商品基本信息',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/commodityManagement',
          },
        },
        {
          path: 'singleProduct',
          name: 'SingleProduct',
          component: () =>
            import(
              '@/views/shopMallProd/pages/commodityManagement/singleProduct'
            ),
          hidden: true,
          meta: {
            title: '单品产品规格属性',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/commodityManagement',
          },
        },
        {
          path: 'portfolioProducts',
          name: 'PortfolioProducts',
          component: () =>
            import(
              '@/views/shopMallProd/pages/commodityManagement/portfolioProducts'
            ),
          hidden: true,
          meta: {
            title: '组合产品规格属性',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/commodityManagement',
          },
        },
        {
          path: 'graphicDetails',
          name: 'GraphicDetails',
          component: () =>
            import(
              '@/views/shopMallProd/pages/commodityManagement/graphicDetails'
            ),
          hidden: true,
          meta: {
            title: '商品基图文详情',
            icon: 'file-import',
            permissions: ['common'],
            routerChild: IS_SHOP_SHOPCENTER,
            activeMenu: '/shopMallProd/commodityManagement',
          },
        },
      ],
    },
  ],
}

// 系统路由
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index/index'),
    meta: { icon: 'indent', title: '登录' },
    hidden: true,
  },
  {
    path: '/register',
    component: () => import('@/views/login/register/index'),
    meta: { icon: 'indent', title: '注册' },
    hidden: true,
  },
  {
    path: '/password',
    component: () => import('@/views/login/password/index'),
    meta: { icon: 'indent', title: '密码' },
    hidden: true,
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401'),
    meta: { icon: 'indent', title: '401页面' },
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: { icon: 'indent', title: '404页面' },
    hidden: true,
  },
]

// kabel使用的所有的菜单列表，（不做任何权限控制）
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    hidden: true,
    meta: { icon: 'indent', title: '--' },
    redirect: { name: 'Clue' },
  },
  // TODO 企业
  {
    path: '/dealer',
    component: Layout,
    name: 'dealer',
    alwaysShow: false,
    meta: {
      title: '经销商管理',
      icon: 'indent',
      auth: 'dealer',
      permissions: [
        'brand_manager',
        'dealer_manager',
        'telesales',
        'viewDealers',
        'dealerManage',
        'finance_manager',
      ],
    },
    children: [
      {
        path: 'list',
        name: 'dealerList',
        component: () => import('@/views/dealer/index/list'),
        meta: {
          title: '经销商列表',
          icon: 'border-all',
          permissions: [
            'brand_manager',
            'dealer_manager',
            'telesales',
            'viewDealers',
            'finance_manager',
          ],
        },
      },
      {
        path: 'companyList',
        name: 'companyList',
        component: () => import('@/views/company/index/list'),
        meta: {
          title: '门店列表',
          icon: 'border-all',
          permissions: [
            'brand_manager',
            'telesales',
            'viewDealers',
            'finance_manager',
          ],
        },
      },
      {
        path: 'companyList/detail',
        name: 'detail',
        meta: { icon: 'indent', title: '经销商管理明细' },
        hidden: true,
        component: () => import('@/views/company/index/detail'),
      },
      {
        path: 'flowList',
        name: 'flowList',
        meta: { icon: 'indent', title: '流水列表' },
        hidden: true,
        component: () => import('@/views/dealer/index/flowList'),
      },
      {
        path: 'flowDetail',
        name: 'flowDetail',
        meta: { icon: 'indent', title: '流水明细' },
        hidden: true,
        component: () => import('@/views/dealer/index/flowDetail'),
      },
    ],
  },
  // TODO 用户中心
  {
    path: '/user',
    component: Layout,
    redirect: 'noRedirect',
    name: 'User',
    alwaysShow: false,
    meta: {
      title: '用户中心',
      icon: 'indent',
      auth: 'user',
      permissions: ['brand_manager', 'shop_manager'],
    },
    children: [
      {
        path: 'roleList',
        name: 'roleList',
        component: () => import('@/views/user/role/list'),
        meta: {
          title: '角色列表',
          icon: 'border-all',
          permissions: ['brand_manager', 'shop_manager'],
        },
      },
      {
        path: 'departmentList',
        name: 'departmentList',
        component: () => import('@/views/department/index/list'),
        meta: {
          title: '组织架构',
          icon: 'border-all',
          permissions: ['brand_manager', 'shop_manager'],
        },
      },
      {
        path: 'cardUser',
        name: 'cardUser',
        alwaysShow: true, // 是否显示下一级
        component: () => import('@/views/user/cardUser/routerIndex'),
        meta: {
          title: '名片用户',
          icon: 'border-all',
          // permissions: ['brand_manager', 'shop_manager'],
        },
        children: [
          {
            path: 'cardUserList',
            name: 'cardUserList',
            component: () => import('@/views/user/cardUser/index'),
            meta: {
              title: '名片用户列表',
              icon: 'list-alt',
              // permissions: ['brand_manager', 'shop_manager'],
            },
          },
        ],
      },
      {
        path: 'leaveInformationUser',
        name: 'leaveInformationUser',
        alwaysShow: true, // 是否显示下一级
        component: () => import('@/views/user/cardUser/routerIndex'),
        meta: {
          title: '留资用户',
          icon: 'border-all',
          // permissions: ['brand_manager', 'shop_manager'],
        },
        children: [
          {
            path: 'leaveInformationUserList',
            name: 'leaveInformationUserList',
            component: () => import('@/views/user/leaveInformationUser/index'),
            meta: {
              title: '留资用户列表',
              icon: 'list-alt',
              // permissions: ['brand_manager', 'shop_manager'],
            },
          },
        ],
      },
      {
        path: 'list/detail',
        name: 'listDetail',
        component: () => import('@/views/user/index/detail'),
        meta: { icon: 'indent', title: '列表明细' },
        hidden: true,
      },
      {
        path: 'roleList/detail',
        name: 'roleDetail',
        component: () => import('@/views/user/role/detail'),
        meta: { icon: 'indent', title: '角色列表明细' },
        hidden: true,
      },
      {
        path: 'list/quitList',
        name: 'quitList',
        component: () => import('@/views/user/index/quitList'),
        meta: { icon: 'indent', title: '--' },
        hidden: true,
      },
    ],
  },
  // 内容中心
  {
    path: '/content',
    component: Layout,
    name: 'Content',
    alwaysShow: true,
    meta: {
      title: '内容',
      icon: 'indent',
      // permissions: ['common'],
      auth: 'content',
      // cache: false,
    },
    children: [
      {
        path: 'investment',
        name: 'cloudInvestment',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '云招商',
          icon: 'indent',
        },
        children: [
          {
            path: 'article',
            name: 'investmentArticle',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'investmentArticleList' },
            meta: {
              title: '招商文章',
              icon: 'indent',
              permissions: ['common'],
              auth: 'article',
            },
            children: [
              {
                path: 'articleList',
                name: 'investmentArticleList',
                component: () => import('@/views/InvestmentArticles/index'),
                hidden: true,
              },
              {
                path: 'publishArticle',
                name: 'investmentPublishArticle',
                meta: { title: '发布文章', icon: 'indent' },
                hidden: true,
                component: () =>
                  import('@/views/InvestmentArticles/publishArticle'),
              },
            ],
          },
          {
            path: 'banner',
            name: 'investmentBanner',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'investmentBannerList' },
            meta: {
              title: 'banner管理',
              icon: 'indent',
              permissions: ['common'],
            },
            children: [
              {
                path: 'bannerList',
                name: 'investmentBannerList',
                component: () => import('@/views/InvestmentBanner/index'),
                hidden: true,
              },
            ],
          },
          {
            path: 'langing',
            name: 'investmentLanding',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'investmentLandingList' },
            meta: {
              title: '落地页管理',
              icon: 'indent',
              permissions: ['common'],
            },
            children: [
              {
                path: 'landingList',
                name: 'investmentLandingList',
                component: () => import('@/views/InvestmentLanding/index'),
                hidden: true,
              },
              {
                path: 'landingUpdate',
                name: 'investmentLandingUpdate',
                component: () =>
                  import('@/views/InvestmentLanding/updateIndex'),
                hidden: true,
              },
            ],
          },
          {
            path: 'openfect',
            name: 'investmentOpenfect',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'investmentOpenfectList' },
            meta: {
              title: '开业实况',
              icon: 'indent',
              permissions: ['common'],
            },
            children: [
              {
                path: 'openfectList',
                name: 'investmentOpenfectList',
                component: () => import('@/views/InvestmentOpenfact/index'),
                hidden: true,
              },
              {
                path: 'openfectUpdate',
                name: 'investmentOpenfectUpdate',
                component: () =>
                  import('@/views/InvestmentOpenfact/updateIndex'),
                hidden: true,
              },
            ],
          },
          {
            path: 'newRetail',
            name: 'investmentnewRetail',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'investmentnewRetailList' },
            meta: {
              title: '新零售赋能',
              icon: 'indent',
              permissions: ['common'],
            },
            children: [
              {
                path: 'newRetailList',
                name: 'investmentnewRetailList',
                component: () => import('@/views/InvestmentnewRetail/index'),
                hidden: true,
              },
              {
                path: 'newRetailUpdate',
                name: 'investmentnewRetailUpdate',
                component: () =>
                  import('@/views/InvestmentnewRetail/updateIndex'),
                hidden: true,
              },
            ],
          },
          {
            path: 'investmentVideo',
            name: 'investmentVideo',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'investmentVideoList' },
            meta: {
              title: '云招商视频',
              icon: 'indent',
              permissions: ['common'],
            },
            children: [
              {
                path: 'investmentVideoList',
                name: 'investmentVideoList',
                component: () => import('@/views/InvestmentVideo/index'),
                hidden: true,
              },
              {
                path: 'investmentVideoUpdate',
                name: 'investmentVideoUpdate',
                component: () => import('@/views/InvestmentVideo/updateIndex'),
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        path: 'case',
        name: 'case',
        redirect: 'noRedirect',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '案例',
          icon: 'indent',
          auth: 'case',
        },
        children: [
          {
            path: 'master_case',
            name: 'master_case',
            // component: () => import('@/views/designerCase/index'),
            component: () => import('@/views/designpptManage/routerIndex'),
            redirect: { name: 'DesignerCaseList' },
            meta: {
              title: '设计师案例',
              icon: 'indent',
              auth: 'master_case',
            },
            children: [
              {
                path: 'list',
                name: 'DesignerCaseList',
                alwaysShow: true,
                component: () => import('@/views/designpptManage/views/index'),
                hidden: true,
                meta: {
                  title: '设计师案例列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
              {
                path: 'add',
                name: 'DesignerCaseAdd',
                alwaysShow: true,
                hidden: true,
                component: () => import('@/views/designpptManage/views/edit'),
                hidden: true,
                meta: {
                  title: '新建',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
            ],
          },
          {
            path: 'local_case',
            name: 'local_case',
            component: () => import('@/views/caseManage/index'),
            redirect: { name: 'CaseManageList' },
            meta: {
              title: '实景案例',
              icon: 'indent',
              auth: 'local_case',
            },
            children: [
              {
                path: 'list',
                name: 'CaseManageList',
                // alwaysShow: true,
                component: () =>
                  import('@/views/caseManage/caseManageList/index'),
                hidden: true,
                meta: {
                  title: '案例列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
              {
                path: 'add',
                name: 'CaseManageAdd',
                // alwaysShow: true,
                hidden: true,
                component: () =>
                  import('@/views/caseManage/caseManageAdd/index'),
                meta: {
                  title: '新增案例',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
            ],
          },
          {
            path: 'show_home',
            name: 'HomePolite',
            redirect: { name: 'ActivitiesList' },
            component: () => import('@/views/homePolite/index'),
            alwaysShow: false,
            meta: {
              title: '晒家有礼',
              icon: 'cogs',
              permissions: ['common'],
              auth: 'show_home',
            },
            children: [
              {
                path: 'show_home_list',
                name: 'ActivitiesList',
                component: () => import('@/views/homePolite/activities/index'),
                hidden: true,
                meta: {
                  title: '',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
              // {
              //   path: 'show_home_auditWork',
              //   name: 'AuditWorkList',
              //   component: () => import('@/views/homePolite/auditWork/index'),
              //   meta: {
              //     title: '作品审核',
              //     icon: 'border-all',
              //     permissions: ['common'],
              //   },
              // },
              {
                path: 'winnerList',
                name: 'WinnerList',
                component: () => import('@/views/homePolite/winnerList/index'),
                hidden: true,
                meta: {
                  title: '作品审核',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'material',
        name: 'material',
        redirect: 'noRedirect',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '素材',
          icon: 'indent',
          auth: 'material',
        },
        children: [
          {
            path: 'article',
            name: 'article',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'articleList' },
            meta: {
              title: '文章',
              icon: 'indent',
              permissions: ['common'],
              auth: 'article',
            },
            children: [
              {
                path: 'articleList',
                name: 'articleList',
                component: () => import('@/views/articleManagement/index'),
                hidden: true,
              },
              {
                path: 'publishArticle',
                name: 'PublishArticle',
                meta: { title: '发布文章', icon: 'indent' },
                hidden: true,
                component: () =>
                  import('@/views/articleManagement/publishArticle'),
              },
            ],
          },
          {
            path: 'materialPromotion',
            name: 'MaterialPromotion',
            meta: {
              title: '推广物料',
              icon: 'indent',
              permissions: ['common'],
              auth: 'picture',
            },
            component: () => import('@/views/materialPromotion/index'),
          },
          {
            path: 'video',
            name: 'video',
            component: () => import('@/views/video/index'),
            redirect: { name: 'videoList' },
            meta: {
              title: '视频',
              icon: 'border-all',
              auth: 'video',
            },
            children: [
              {
                path: 'videoList',
                name: 'videoList',
                component: () => import('@/views/video/videoManage/index'),
                hidden: true,
              },
              {
                path: 'edit',
                name: 'editVideo',
                component: () => import('@/views/video/videoManage/editVideo'),
                meta: {
                  title: '发布视频',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
                hidden: true,
              },
            ],
          },
          // {
          //   path: 'vr',
          //   name: 'vr',
          //   component: () => import('@/views/vr/index'),
          //   redirect: { name: 'vrList' },
          //   meta: {
          //     title: 'VR',
          //     icon: 'border-all',
          //     permissions: ['common'],
          //     auth: 'vr',
          //     cache: false,
          //   },
          //   children: [
          //     {
          //       path: 'list',
          //       name: 'vrList',
          //       component: () => import('@/views/vr/vrManage/index'),
          //       hidden: true,
          //     },
          //     {
          //       path: 'edit',
          //       name: 'editVr',
          //       component: () => import('@/views/vr/vrManage/editVr'),
          //       meta: {
          //         title: '发布VR',
          //         icon: 'border-all',
          //         permissions: ['common'],
          //         cache: false,
          //       },
          //       hidden: true,
          //     },
          //   ],
          // },
        ],
      },
      {
        path: 'words',
        name: 'VerbalTrick',
        component: () => import('@/views/verbalTrick/index'),
        alwaysShow: true, // 是否显示下一级
        meta: {
          title: '话术库',
          icon: 'border-all',
          cache: false,
          auth: 'words',
        },
        children: [
          {
            path: 'words_list',
            name: 'VerbalTrickAdministrative',
            // alwaysShow: true,
            component: () =>
              import('@/views/verbalTrick/verbalTrickAdministrative/index'),
            meta: {
              title: '话术列表',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_list',
              cache: false,
            },
          },
          {
            path: 'words_category',
            name: 'VerbalTrickClassify',
            // alwaysShow: true,
            component: () =>
              import('@/views/verbalTrick/verbalTrickClassify/index'),
            meta: {
              title: '话术分类',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
        ],
      },
      {
        path: 'essentialData',
        name: 'EssentialData',
        component: () => import('@/views/essentialData/index'),
        alwaysShow: true, // 是否显示下一级
        meta: {
          title: '基础数据',
          icon: 'border-all',
          cache: false,
          auth: 'words',
        },
        children: [
          {
            path: 'articleClassification',
            name: 'ArticleClassification',
            // alwaysShow: true,
            component: () =>
              import('@/views/essentialData/articleClassification/index'),
            meta: {
              title: '文章分类',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
          {
            path: 'interspace',
            name: 'Interspace',
            // alwaysShow: true,
            component: () => import('@/views/essentialData/interspace/index'),
            meta: {
              title: '空间',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
          {
            path: 'styleList',
            name: 'StyleList',
            // alwaysShow: true,
            component: () => import('@/views/essentialData/styleList/index'),
            meta: {
              title: '风格',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
          {
            path: 'propertyList',
            name: 'PropertyList',
            // alwaysShow: true,
            component: () => import('@/views/essentialData/propertyList/index'),
            meta: {
              title: '楼盘列表',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
        ],
      },
    ],
  },
  // 营销中心
  {
    path: '/market',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Marketing',
    // alwaysShow: true, // 是否显示下一级
    meta: { title: '营销', icon: 'indent', auth: 'marketing' },
    children: [
      {
        path: '/loverActivity',
        component: () => import('@/views/market/loverActivity'),
        name: 'loverActivity',
        meta: {
          title: '520专题活动',
          icon: 'indent',
          auth: 'order',
        },
      },
      {
        path: '/doubleEleven',
        component: () => import('@/views/market/doubleEleven.vue'),
        name: 'doubleEleven',
        meta: {
          title: '双十一活动',
          icon: 'indent',
          auth: 'order',
        },
      },
      {
        path: 'marketing',
        name: 'marketing',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '营销玩法',
          icon: 'indent',
          auth: 'marketing',
        },
        children: [
          {
            path: 'red_envelope',
            name: 'Redpacket',
            component: () => import('@/views/market/index'),
            redirect: { name: 'redpacketList' },
            meta: {
              title: '红包拉新',
              icon: 'list-alt',
              permissions: ['common'],
              auth: 'red_envelope',
            },
            children: [
              {
                path: 'redpacketList',
                name: 'redpacketList',
                component: () => import('@/views/market/redpacket/index'),
                meta: {
                  title: '活动列表',
                  icon: 'list-alt',
                  permissions: ['common'],
                },
                hidden: true,
              },
              {
                path: 'newRedpacket',
                name: 'newRedpacket',
                component: () => import('@/views/market/redpacket/edit'),
                meta: {
                  title: '新建',
                  icon: 'list-alt',
                  permissions: ['common'],
                },
                hidden: true,
              },
            ],
          },
          {
            path: 'haggle',
            component: () => import('@/views/seckill/index'),
            redirect: { name: 'BargainList' },
            name: 'seckill',
            alwaysShow: false,
            meta: {
              title: '砍价',
              icon: 'cogs',
              permissions: ['common'],
              auth: 'haggle',
            },
            children: [
              {
                path: 'haggle_list',
                name: 'BargainList',
                component: () => import('@/views/seckill/bargainList'),
                meta: {
                  title: '砍价列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  auth: 'haggle_list',
                },
              },
              {
                path: 'user_list',
                name: 'SeckillList',
                component: () => import('@/views/seckill/seckillList'),
                meta: {
                  title: '参与用户列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  auth: 'user_list',
                },
              },
              {
                path: 'haggle_create',
                name: 'CreateActivities',
                component: () => import('@/views/seckill/createActivities'),
                hidden: true,
                meta: {
                  title: '创建活动',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
              {
                path: 'haggle_detail',
                name: 'BargainDetails',
                component: () => import('@/views/seckill/bargainDetails'),
                hidden: true,
                meta: {
                  title: '砍价详情',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
              {
                path: 'verification_record',
                name: 'CancelVerificationList',
                component: () =>
                  import('@/views/seckill/cancelVerificationList'),
                meta: {
                  title: '核销记录',
                  icon: 'border-all',
                  permissions: ['common'],
                  auth: 'verification_record',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'anti_fake',
        name: 'antifake',
        component: () => import('@/views/caseManage/index'),
        alwaysShow: true,
        redirect: 'noRedirect',
        meta: {
          title: '防伪',
          icon: 'indent',
          auth: 'anti_fake',
          permissions: ['common'],
        },
        children: [
          {
            path: 'certificate',
            name: 'certificateList',
            component: () => import('@/views/antifake/certificateList/index'),
            meta: {
              title: '合格证',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'certificate',
            },
          },
          {
            path: 'anti_fake_list',
            name: 'fakeList',
            component: () => import('@/views/antifake/fakeList/index'),
            meta: {
              title: '打假名单',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'anti_fake_list',
            },
          },
        ],
      },
    ],
  },
  // TODO scrm新版本线索菜单（派加使用新版本菜单，Kabel使用旧版本）
  {
    path: '/scrm',
    name: 'scrm',
    component: Layout,
    redirect: 'noRedirect',
    alwaysShow: true, // 是否显示下一级
    meta: { title: 'SCRM', icon: 'indent', auth: 'scrm' },
    children: [
      {
        path: 'clue',
        name: 'Clue',
        redirect: { name: 'clueList' },
        component: () => import('@/views/clue/index'),
        alwaysShow: true, // 是否显示下一级
        meta: { title: '线索', icon: 'indent', auth: 'clues' },
        children: [
          {
            path: 'list',
            name: 'clueList',
            component: () => import('@/views/clue/toc/list/index'),
            meta: {
              title: '线索列表',
              icon: 'list-alt',
              // auth: 'clues_list',
              // icon: 'file-import',
              // permissions: ['common'],
            },
          },
          {
            path: 'import',
            name: 'ClueImport',
            component: () => import('@/views/clue/toc/import/index'),
            meta: {
              title: '线索导入',
              icon: 'file-import',
              auth: 'clues_import',
              permissions: ['common'],
            },
          },
        ],
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/views/clue/index'),
        alwaysShow: true,
        meta: {
          title: '设置',
          icon: 'indent',
          auth: 'scrm_setting',
        },
        children: [
          {
            path: 'clueSetting',
            name: 'ClueSetting',
            component: () => import('@/views/clue/setting/ClueSetting'),
            meta: {
              title: '线索设置',
              icon: 'edit',
              auth: 'clues_setting',
              permissions: ['common'],
            },
          },
        ],
      },
    ],
  },
  // TODO 品牌-商品中心
  brand_shop_center,
  // TODO 门店-商品中心
  shop_shop_center,
  // 门店采购管理
  {
    path: '/pagaCloudStorePurMgt',
    component: Layout,
    redirect: 'noRedirect',
    name: 'PagaCloudStorePurMgt',
    alwaysShow: true,
    meta: {
      title: '采购管理（门店）',
      icon: 'indent',
      auth: 'purchase_outlet',
      permissions: ['shop_manager', 'shop_user'],
      cache: false,
    },
    children: [
      {
        path: 'purchaseOrder/purMall',
        name: 'PurMallStore',
        component: () => import('@/views/pagaCloudStorePurMgt/pages/purMall'),
        meta: {
          title: '采购商城',
          icon: 'border-all',
          cache: false,
          permissions: ['shop_manager', 'shop_user'],
          auth: 'purchase_shopping',
        },
      },
      {
        path: 'purchaseOrder/orderDetail',
        name: 'SorderDetail',
        component: () =>
          import('@/views/pagaCloudStorePurMgt/pages/orderDetail'),
        hidden: true,
        meta: {
          title: '订单详情',
          icon: 'border-all',
          permissions: ['shop_manager', 'shop_user'],
          cache: false,
        },
      },
      {
        path: 'purchaseOrder/procurementVehicle',
        name: 'ProcurementVehicleStore',
        component: () =>
          import('@/views/pagaCloudStorePurMgt/pages/procurementVehicle'),
        meta: {
          title: '采购车',
          icon: 'border-all',
          cache: false,
          permissions: ['shop_manager', 'shop_user'],
          auth: 'purchase_procurementVehicle',
        },
      },
      {
        path: 'purchaseOrder/orderList',
        name: 'OrderListStore',
        component: () => import('@/views/pagaCloudStorePurMgt/pages/orderList'),
        meta: {
          title: '采购订单',
          icon: 'border-all',
          cache: false,
          auth: 'purchase_list',
          permissions: ['shop_manager', 'shop_user'],
        },
      },
      {
        path: 'purchaseOrder/purSetting',
        name: 'PurSettingStore',
        component: () =>
          import('@/views/pagaCloudStorePurMgt/pages/purSetting'),
        meta: {
          title: '采购设置',
          auth: 'purchase_set',
          icon: 'border-all',
          permissions: ['shop_manager', 'shop_user'],
          cache: false,
        },
      },
    ],
  },
  // 品牌采购管理
  {
    path: '/pagaCloudBrandPurMgt',
    component: Layout,
    redirect: 'noRedirect',
    name: 'PagaCloudBrandPurMgt',
    alwaysShow: true,
    meta: {
      title: '采购管理（品牌）',
      icon: 'indent',
      auth: 'purchasing_management',
      permissions: ['brand_manager', 'brand_user'],
      cache: false,
    },
    children: [
      {
        path: 'purchaseOrder/orderList',
        name: 'OrderList',
        component: () => import('@/views/pagaCloudBrandPurMgt/pages/orderList'),
        meta: {
          title: '采购订单',
          icon: 'border-all',
          cache: false,
          auth: 'purchasing_list',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/shoppingMall',
        name: 'ShoppingMall',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/shoppingMall'),
        meta: {
          title: '采购商城',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/couponManagement',
        name: 'CouponManagement',
        component: () =>
          import('@/views/couponManagement/pages/couponManagement.vue'),
        meta: {
          title: '优惠券管理',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/createCoupon',
        name: 'createCoupon',
        component: () =>
          import('@/views/couponManagement/pages/createCoupon.vue'),
        hidden: true,
        meta: {
          title: '优惠券管理',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/allCouponDetail',
        name: 'allCouponDetail',
        component: () =>
          import('@/views/couponManagement/pages/allCouponDetail.vue'),
        hidden: true,
        meta: {
          title: '优惠券管理',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/shoppingCardManageLists',
        name: 'shoppingCardManageLists',
        component: () =>
          import(
            '@/views/pagaCloudBrandPurMgt/pages/shoppingCardManage/index.vue'
          ),
        hidden: false,
        meta: {
          title: '购物卡管理',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'shop_card_manage'], // 管理员 || 拥有特殊权限可查看购物卡的部门
        },
      },
      {
        path: 'purchaseOrder/shoppingCar',
        name: 'shoppingCar',
        redirect: { name: 'shoppingCardManageList' },
        component: () => import('@/views/routerIndex'),
        hidden: true,
        meta: {
          title: '购物卡管理',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
        // 此路由有多层，只需要控制当前最顶层的权限就可以
        children: [
          {
            path: 'shoppingCardManageList',
            name: 'shoppingCardManageList',
            component: () =>
              import(
                '@/views/pagaCloudBrandPurMgt/pages/shoppingCardManage/index.vue'
              ),
            hidden: true,
          },
          {
            path: 'addShopCard',
            name: 'addShopCard',
            component: () =>
              import(
                '@/views/pagaCloudBrandPurMgt/pages/addShopCard/index.vue'
              ),
            hidden: true,
            meta: {
              title: '新增购物卡',
              // auth: 'purchasing_shopping',
              icon: 'border-all',
              // cache: false,
              // permissions: ['brand_manager', 'brand_user'],
            },
          },
          {
            path: 'shoppingCardDet',
            name: 'shoppingCardDet',
            component: () => import('@/views/routerIndex'),
            hidden: true,
            meta: {
              title: '购物卡明细',
              // auth: 'purchasing_shopping',
              icon: 'border-all',
              // cache: false,
              // permissions: ['brand_manager', 'brand_user'],
            },
            children: [
              {
                path: 'shoppingCardDetail',
                name: 'shoppingCardDetail',
                component: () =>
                  import(
                    '@/views/pagaCloudBrandPurMgt/pages/shoppingCardManage/detail.vue'
                  ),
                hidden: true,
              },
              {
                path: 'balanceChangeDetail',
                name: 'balanceChangeDetail',
                component: () =>
                  import(
                    '@/views/pagaCloudBrandPurMgt/pages/shoppingCardManage/balanceChangeDetail.vue'
                  ),
                hidden: true,
                meta: {
                  title: '余额变动明细',
                  // auth: 'purchasing_shopping',
                  icon: 'border-all',
                  // cache: false,
                  // permissions: ['brand_manager', 'brand_user'],
                },
              },
              {
                path: 'exportShopCard',
                name: 'exportShopCard',
                component: () =>
                  import(
                    '@/views/pagaCloudBrandPurMgt/pages/shoppingCardManage/export.vue'
                  ),
                hidden: true,
                meta: {
                  title: '导入',
                  // auth: 'purchasing_shopping',
                  icon: 'border-all',
                  // cache: false,
                  // permissions: ['brand_manager', 'brand_user'],
                },
              },
              {
                path: 'exportDetailList',
                name: 'exportDetailList',
                component: () =>
                  import(
                    '@/views/pagaCloudBrandPurMgt/pages/shoppingCardManage/exportDetailList.vue'
                  ),
                hidden: true,
                meta: {
                  title: '导入明细',
                  // auth: 'purchasing_shopping',
                  icon: 'border-all',
                  // cache: false,
                  // permissions: ['brand_manager', 'brand_user'],
                },
              },
            ],
          },
        ],
      },

      {
        path: 'purchaseOrder/couponDetail',
        name: 'couponDetail',
        component: () =>
          import('@/views/couponManagement/pages/couponDetail.vue'),
        hidden: true,
        meta: {
          title: '发放详情',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/orderDetail',
        name: 'PorderDetail',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/orderDetail'),
        hidden: true,
        meta: {
          title: '订单详情',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/purSetting',
        name: 'PurSetting',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/purSetting'),
        meta: {
          title: '采购设置',
          auth: 'purchasing_set',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/examineOrder',
        name: 'OrderDetail',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/examineOrder'),
        hidden: true,
        meta: {
          title: '审核订单',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/expressDelivery',
        name: 'ExpressDelivery',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/expressDelivery'),
        hidden: true,
        meta: {
          title: '快速发货',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/logisticsQuickly',
        name: 'LogisticsQuickly',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/logisticsQuickly'),
        hidden: true,
        meta: {
          title: '快速填写物流',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'logistics',
        name: 'Logistics',
        component: () => import('@/views/order/logistics/index'),
        hidden: true,
        meta: {
          title: '导入物流',
          icon: 'border-all',
        },
      },
      {
        path: 'lead',
        name: 'lead',
        component: () => import('@/views/order/logistics/fhindex'),
        hidden: true,
        meta: {
          title: '导入出库单',
          icon: 'border-all',
          // permissions: ['shop_goods_user', 'warehouse'],
        },
      },
      {
        path: 'logisticsdetailList',
        name: 'logisticsdetailList',
        component: () => import('@/views/order/logistics/detailList'),
        hidden: true,
        meta: {
          title: '明细列表',
          icon: 'border-all',
        },
      },
    ],
  },
  // TODO 订单
  {
    path: '/order',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Order',
    alwaysShow: true,
    meta: {
      title: '订单',
      icon: 'indent',
      permissions: ['shop_goods_user', 'warehouse', 'shop_goods_people'],
      auth: 'order',
    },
    children: [
      {
        path: 'importall',
        name: 'OrderImportall',
        component: () => import('@/views/order/importall/index'),
        meta: {
          title: '全部订单导出',
          icon: 'border-all',
          permissions: ['shop_goods_user'],
        },
      },
      {
        path: 'importjd',
        name: 'OrderImportjd',
        component: () => import('@/views/order/importjd/index'),
        meta: {
          title: '金蝶订单导出',
          icon: 'border-all',
          permissions: ['shop_goods_user', 'shop_goods_people'],
        },
      },
      {
        path: 'imporclod',
        name: 'OrderImporclod',
        component: () => import('@/views/order/imporclod/index'),
        meta: {
          title: '云星空订单导出',
          icon: 'border-all',
          permissions: ['shop_goods_user'],
          auth: 'order_jd_export',
        },
      },
    ],
  },
  // 商品
  {
    path: '/commodity',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Commodity',
    alwaysShow: false,
    meta: { title: '商品', icon: 'indent', permissions: ['shop_goods_user'] },
    children: [
      {
        path: 'import',
        name: 'CommodityImport',
        component: () => import('@/views/commodity/import/index'),
        meta: {
          title: '商品导出',
          icon: 'border-all',
          permissions: ['shop_goods_user'],
        },
      },
    ],
  },
  // 品牌中心
  {
    path: '/brand',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Brand',
    alwaysShow: true,
    meta: {
      title: '品牌',
      icon: 'indent',
      // auth: 'brand',
      permissions: ['brand_manager', 'shop_manager'],
    },
    children: [
      {
        path: 'brand_list',
        name: 'Brand_list',
        component: () => import('@/views/shopMallProd/pages/index'),
        redirect: { name: 'BrandListIndex' },
        meta: {
          title: '品牌管理',
          icon: 'border-all',
          auth: 'brand_list',
        },
        children: [
          {
            path: 'brandListIndex',
            name: 'BrandListIndex',
            component: () => import('@/views/shopMallProd/pages/brand/index'),
            hidden: true,
            meta: {
              title: '品牌管理列表',
              icon: 'list-alt',
            },
          },
        ],
      },
    ],
  },
  // TODO 公告管理
  {
    path: '/notice',
    component: Layout,
    redirect: '/notice/list',
    name: 'Notice',
    alwaysShow: true, // 是否显示下一级
    meta: { title: '公告管理', icon: 'indent' },
    children: [
      {
        path: 'list',
        name: 'List',
        component: () => import('@/views/notice/list/index'),
        meta: {
          title: '公告列表',
          icon: 'list-alt',
        },
      },
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/views/notice/detail/index'),
        hidden: true,
        meta: {
          title: '公告详情',
          icon: 'list-alt',
          activeMenu: '/notice/list',
        },
      },
    ],
  },
  // TODO 结算管理
  {
    path: '/settlement',
    component: Layout,
    redirect: '/settlement/list',
    name: 'settlement',
    alwaysShow: true, // 是否显示下一级
    meta: { title: '结算管理', icon: 'indent' },
    children: [
      {
        path: 'wallet',
        name: 'Wallet',
        component: () => import('@/views/settlement/wallet'),
        meta: {
          title: '门店钱包明细',
          icon: 'list-alt',
        },
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/settlement/order'),
        meta: {
          title: '门店订单明细',
          icon: 'list-alt',
        },
      },
    ],
  },
  // TODO 意见反馈
  {
    path: '/feedback',
    component: Layout,
    redirect: { name: 'FeedbackList' },
    name: 'Feedback',
    alwaysShow: false, // 是否显示下一级
    meta: { title: '意见反馈管理', icon: 'indent' },
    children: [
      {
        path: 'list',
        name: 'FeedbackList',
        component: () => import('@/views/feedback/views/index'),
        hidden: true,
        meta: {
          title: '列表',
          icon: 'list-alt',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

// 派加云业务中台
export const pagaCloudBackgroundRoutes = pagaCloudBackground
// 商城管理后台
export const shopMallBackgroundRoutes = shopMallBackground

const router = new VueRouter({
  // base:
  //   Number(localStorage.getItem('backstage')) === 1
  //     ? '/shopMall'
  //     : Number(localStorage.getItem('backstage')) === 2
  //     ? '/paiplusCloud'
  //     : '',
  base: '/',
  mode: routerMode,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRoutes,
})

export function resetRouter() {
  location.reload()
}

export default router
